import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from "gatsby";
import More from "../../components/more-toggle";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className='callout'>The <Link to='/introduction' mdxType="Link">introduction</Link> to longtermism on this site gives an overview of some ideas associated with longtermism. On this page, we’ve listed some external resources — books, articles, podcasts, and videos — for going further.</div>
    <h2 className='centered-header'>Books</h2>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://theprecipice.com/"
        }}><em parentName="a">{`The Precipice`}</em></a>{` by Toby Ord (2020)`}</strong></p>
    <p>{`This path-breaking book is a good place to start. It explores the science and philosophy behind the large-scale risks we face. It puts them in the context of the greater story of humanity: showing how ending these risks is among the most pressing moral issues of our time. And it points the way forward, to the actions and strategies we can take today to safeguard humanity’s future.`}</p>
    <p>{`For a briefer introduction to the ideas in `}<em parentName="p">{`The Precipice`}</em>{`, you can `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=CrMIEz_mSJM&t=290s"
      }}><strong parentName="a">{`watch this 20-minute presentation`}</strong></a>{`, narrated by the author. If you prefer podcasts, `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/episodes/why-the-long-run-future-matters-more-than-anything-else-and-what-we-should-do-about-it/"
      }}><strong parentName="a">{`we recommend this interview`}</strong></a>{` with Toby about why the long-term future of humanity matters more than anything else, and what we should do about it.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://whatweowethefuture.com"
        }}><em parentName="a">{`What We Owe The Future`}</em></a>{` by William MacAskill (2022)`}</strong></p>
    <p>{`An exploration of the philosophical and empirical case for longtermism. The book  describes some of the ways we can influence the long-term future, and philosophical questions like "is it good to make happy people?" and "will the future be good or bad?". It also investigates the history of changes in our moral beliefs, and argues that developments like the abolition of slavery may have been surprisingly contingent upon the actions of small groups of individuals. `}</p>
    <p>{`There are lots of podcast discussions of the book. `}<a parentName="p" {...{
        "href": "https://www.nytimes.com/2022/08/09/opinion/ezra-klein-podcast-will-macaskill.html"
      }}>{`Ezra Klein's New York Times interview with the author is a good place to start`}</a>{`. Four of the best author interviews are listed `}<a parentName="p" {...{
        "href": "https://twitter.com/peterhartree/status/1559568673920016384"
      }}>{`here`}</a>{`.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://philpapers.org/rec/CARTLV-2"
        }}><em parentName="a">{`The Long View: Essays on Policy, Philanthropy, and the Long-term Future`}</em></a>{` by Natalie Cargill & Tyler M. John (eds.)`}</strong></p>
    <p>{`Described as “a guidebook for philanthropists, advocates, and policymakers who want to do the most good possible”, this free ebook collects essays “from the pioneers who have developed the intellectual foundations of longtermism, as well as the experts and advocates who are now putting it into practice”. The book contains policy proposals from political philosophers, members of the House of Lords, and the All-party Parliamentary Group for Future Generations; the case for work on biosecurity, artificial intelligence, and climate change from leading experts; and the first ever essay on longtermist cultural change from the former Chief of Cabinet to the Finnish President.`}</p>
    <h2 className='centered-header'>Writing</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bbc.com/future/article/20220805-what-is-longtermism-and-why-does-it-matter"
        }}><strong parentName="a">{`What is longtermism?`}</strong></a>{` by William MacAskill. This brief BBC article outlines the case for longtermism, and gives some examples of relevant organisations and projects.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cold-takes.com/most-important-century/"
        }}><strong parentName="a">{`The "most important century" blog post series`}</strong></a>{` by Holden Karnofsky. This series of blog posts argues that "`}<strong parentName="li">{`the 21st century could be the most important century ever for humanity,`}</strong>{` via the development of advanced AI systems that could dramatically speed up scientific and technological advancement, getting us more quickly than most people imagine to a deeply unfamiliar future." If you only have time to read one thing, this would be a great place to start.`}
        <ul parentName="li">
          <li parentName="ul">{`You can also listen to this series of blog posts on `}<a parentName="li" {...{
              "href": "https://open.spotify.com/show/3CocRoFqE6UI4EmyfMevUj"
            }}>{`Spotify`}</a>{` or `}<a parentName="li" {...{
              "href": "https://podcasts.apple.com/podcast/id1586669292"
            }}>{`Apple Podcasts`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ourworldindata.org/longtermism"
        }}><strong parentName="a">{`The Future is Vast: Longtermism’s perspective on humanity’s past, present, and future`}</strong></a>{` — this essay by Max Roser from `}<a parentName="li" {...{
          "href": "https://ourworldindata.org"
        }}>{`Our World in Data`}</a>{` uses infographics to convey the view that "if we manage to avoid a large catastrophe, we are living at the early beginnings of human history".`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://80000hours.org/articles/future-generations/"
        }}><strong parentName="a">{`Why our impact in millions of years could be what most matters`}</strong></a>{` — this article on the `}<a parentName="li" {...{
          "href": "https://80000hours.org/"
        }}><strong parentName="a">{`80,000 Hours website`}</strong></a>{` discusses the expected size of the future, and our moral obligations to future generations. The`}<a parentName="li" {...{
          "href": "https://80000hours.org/articles/future-generations/#longtermism-in-a-nutshell"
        }}>{` `}<strong parentName="a">{`'longtermism in a nutshell'`}</strong></a>{` section is especially useful.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nytimes.com/2022/08/05/opinion/the-case-for-longtermism.html"
        }}><strong parentName="a">{`The Case for Longtermism`}</strong></a>{` by William MacAskill. This New York Times article lays out the philosophical case for longtermism, starting with a thought experiment: "Imagine living the life of every human being who has ever existed — in order of birth."`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.effectivealtruism.org/articles/longtermism/"
        }}><strong parentName="a">{`Introduction to Longtermism`}</strong></a>{` — this article on `}<a parentName="li" {...{
          "href": "https://www.effectivealtruism.org"
        }}><strong parentName="a">{`effectivealtruism.org`}</strong></a>{` gives a slightly longer overview of longtermism, considering various definitions and objections.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.effectivealtruism.org/articles/cause-profile-long-run-future/"
        }}><strong parentName="a">{`The Long-Term Future`}</strong></a>{` — AI researcher `}<a parentName="li" {...{
          "href": "https://jesswhittlestone.com/"
        }}><strong parentName="a">{`Jess Whittlestone`}</strong></a>{` discusses the case for the long-term future as a target of altruism.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Longtermism"
        }}><strong parentName="a">{`Wikipedia page`}</strong></a>{` for longtermism — including history, criticism, and many references`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.forethought.org/longtermism"
        }}><strong parentName="a">{`Longtermism: Potential Research Projects`}</strong></a>{` — background on longtermism and potential research projects from the Forethought Foundation for Global Priorities Research.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://press.stripe.com/stubborn-attachments"
        }}><strong parentName="a">{`Stubborn Attachments`}</strong></a>{` by Tyler Cowen — this short book argues for one of many conclusions that could emerge from thinking over long timescales: that we should "set our sights on the long-term struggles that maximize sustainable economic growth while respecting human rights."`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/book/show/745703.Maps_of_Time"
        }}><strong parentName="a">{`Maps of Time: An Introduction to Big History`}</strong></a>{` by David Christian — the seminal introduction to 'big history', studying themes and trends from all of human history, and far beyond.`}</li>
    </ul>
    <More title="Go further: click to see writing about long-term thinking" mdxType="More">
      <p>{`Long-term thinking means imagining projects and processes which could last thousands of years — thinking at the timescale of civilisation. It's a close relative, and a major inspiration, of longtermism.`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.goodreads.com/book/show/51107158-the-good-ancestor"
          }}><strong parentName="a">{`The Good Ancestor`}</strong></a>{` by Roman Krznaric `}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.goodreads.com/book/show/33279.Clock_of_the_Long_Now"
          }}><strong parentName="a">{`Clock of the Long Now`}</strong></a>{` by Stewart Brand`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://longtermist.substack.com/"
          }}><strong parentName="a">{`The Long-termist's Field Guide`}</strong></a>{` by Richard Fisher`}</li>
      </ul>
    </More>
    <h2 className='centered-header'>Talks and Podcasts</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.nytimes.com/2022/08/09/opinion/ezra-klein-podcast-will-macaskill.html"
          }}><strong parentName="a">{`Ezra Klein (New York Times) interviews Will MacAskill`}</strong></a>{` – an audio introduction to longtermism. Klein starts the conversation with three sentences: "Future people count. There could be a lot of them. We can make their lives better."`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://80000hours.org/podcast/"
          }}><strong parentName="a">{`The 80,000 Hours podcast`}</strong></a>{` — in addition to their interview with Toby, the 80,000 Hours podcast has more than 100 thoughtful and in-depth conversations about the world's most pressing problems and what you can do to solve them. Many of these focus on aspects of longtermism. We particularly recommend:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://80000hours.org/podcast/episodes/ajeya-cotra-worldview-diversification/"
            }}><strong parentName="a">{`Ajeya Cotra on worldview diversification and how big the future could be`}</strong></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://80000hours.org/podcast/episodes/ben-todd-on-varieties-of-longtermism/"
            }}><strong parentName="a">{`Benjamin Todd on varieties of longtermism`}</strong></a>{` — the first half of this episode discusses a way to distinguish different approaches to longtermism.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://80000hours.org/podcast/episodes/luisa-rodriguez-why-global-catastrophes-seem-unlikely-to-kill-us-all/"
            }}><strong parentName="a">{`Luisa Rodriguez on why global catastrophes seem unlikely to kill us all`}</strong></a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You may also enjoy these podcast episodes from beyond the 80,000 Hours podcast —`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://rationallyspeakingpodcast.org/261-dangerous-biological-research-is-it-worth-it-kevin-esvelt/"
            }}><strong parentName="a">{`Dangerous biological research – is it worth it?`}</strong></a>{` (`}<a parentName="li" {...{
              "href": "http://rationallyspeakingpodcast.org"
            }}>{`Rationally Speaking Podcast with Julia Galef`}</a>{`)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://futureoflife.org/2018/09/27/podcast-ai-and-nuclear-weapons-trust-accidents-and-new-risks-with-paul-scharre-and-mike-horowitz/"
            }}><strong parentName="a">{`Paul Scharre and Mike Horowitz on AI and nuclear weapons`}</strong></a>{` (`}<a parentName="li" {...{
              "href": "https://futureoflife.org/the-future-of-life-podcast/"
            }}>{`Future of Life Institute podcast`}</a>{`)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://hearthisidea.com/episodes/thomas"
            }}><strong parentName="a">{`Thomas Moynihan on the History of Existential Risk`}</strong></a>{` (`}<a parentName="li" {...{
              "href": "https://hearthisidea.com/"
            }}>{`Hear This Idea`}</a>{` podcast)`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=Ccq2Ql8FcY0"
          }}><strong parentName="a">{`Orienting towards the long-term future`}</strong></a>{` — in this 25-minute video, researcher Joseph Carlsmith talks about the focus on improving the long-term future that motivates some people in the effective altruism community.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=vCpFsvYI-7Y"
          }}><strong parentName="a">{`What we owe the future`}</strong></a>{` — in this 40-minute talk, Oxford philosopher William MacAskill discusses how human history up to the present day points to the importance of positively influencing the long-run future, and that now could be an unusually influential time for doing so. William is currently writing a book about this topic, titled `}<em parentName="p">{`What We Owe The Future`}</em>{`, scheduled for publication in 2022.`}</p>
      </li>
    </ul>
    <h2 className='centered-header'>Philosophy</h2>
    <p>{`The articles below consider philosophical aspects of longtermism in more detail. They are a little more technical than the resources above.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://globalprioritiesinstitute.org/hilary-greaves-william-macaskill-the-case-for-strong-longtermism-2/"
        }}><strong parentName="a">{`The case for strong longtermism`}</strong></a>{` — Hilary Greaves and William MacAskill consider strong versions of longtermism, and reasons for embracing them.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://globalprioritiesinstitute.org/christian-tarsney-the-epistemic-challenge-to-longtermism/"
        }}><strong parentName="a">{`The epistemic challenge to longtermism`}</strong></a>{` — Christian Tarsney discusses one major objection to longtermism: that the effects of our actions over the long-term future may be nearly impossible to predict.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://globalprioritiesinstitute.org/tyler-m-john-and-william-macaskill-longtermist-institutional-reform/"
        }}><strong parentName="a">{`Longtermist institutional reform`}</strong></a>{` — Tyler M. John and William MacAskill discuss the reasons that future generations are neglected in politics, and consider some ways political institutions could begin to represent the interests of future people.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://forum.effectivealtruism.org/posts/NYxaxLZS5tiiyiHnG/a-full-syllabus-on-longtermism?fbclid=IwAR3rpu3yUoM9msYb7XktFk_c1Exx87ZVHR1gOCPE18z27rfC7hkqvaJy4ms"
        }}><strong parentName="a">{`A full syllabus on longtermism`}</strong></a>{` — a comprehensive list of writings about the philosophy of longtermism. You can see `}<a parentName="li" {...{
          "href": "https://www.stafforini.com/blog/courses-on-longtermism/"
        }}><strong parentName="a">{`more courses on longtermism`}</strong></a>{` `}<strong parentName="li">{`here, plus this`}</strong><a parentName="li" {...{
          "href": "https://theprecipice.com/syllabus"
        }}>{` `}<strong parentName="a">{`syllabus on existential risk`}</strong></a>{` based on `}<a parentName="li" {...{
          "href": "https://theprecipice.com/"
        }}><em parentName="a">{`The Precipice`}</em></a>{`.`}</li>
    </ul>
    <h2 className='centered-header'>Historical</h2>
    <p>{`Many of the central ideas of contemporary longtermism can trace roots to early thinkers and advocates. Below is a small selection of earlier writing pertinent to present-day longtermism.`}</p>
    <ul>
      <li parentName="ul">{`Frank Ramsey, `}<a parentName="li" {...{
          "href": "https://www.jstor.org/stable/2224098"
        }}><strong parentName="a">{`A Mathematical Theory of Saving`}</strong></a>{` (1928)`}</li>
      <li parentName="ul">{`Winston Churchill, `}<a parentName="li" {...{
          "href": "https://www.nationalchurchillmuseum.org/fifty-years-hence.html"
        }}><strong parentName="a">{`Fifty Years Hence`}</strong></a>{` (1931)`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.atomicheritage.org/key-documents/russell-einstein-manifesto"
        }}><strong parentName="a">{`Russell-Einstein Manifesto`}</strong></a>{` (1955)`}</li>
      <li parentName="ul">{`John von Neumann, `}<a parentName="li" {...{
          "href": "http://geosci.uchicago.edu/~kite/doc/von_Neumann_1955.pdf"
        }}><strong parentName="a">{`Can We Survive Technology?`}</strong></a>{` (1955)`}</li>
      <li parentName="ul">{`Jonathan Schell, `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/The_Fate_of_the_Earth"
        }}><strong parentName="a">{`The Fate of the Earth`}</strong></a>{` (1982)`}</li>
      <li parentName="ul">{`Derek Parfit, `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Reasons_and_Persons"
        }}><strong parentName="a">{`Reasons and Persons`}</strong></a>{` (1984)`}</li>
      <li parentName="ul">{`Annette Baier, `}<a parentName="li" {...{
          "href": "https://oxford.universitypressscholarship.com/view/10.1093/acprof:osobl/9780199570362.001.0001/acprof-9780199570362-chapter-1"
        }}><strong parentName="a">{`The Rights of Past and Future Persons`}</strong></a>{` (1981)`}</li>
      <li parentName="ul">{`Carl Sagan, `}<a parentName="li" {...{
          "href": "https://www.goodreads.com/book/show/61663.Pale_Blue_Dot"
        }}><strong parentName="a">{`Pale Blue Dot`}</strong></a>{`, 1994`}</li>
      <li parentName="ul">{`UNESCO `}<a parentName="li" {...{
          "href": "http://portal.unesco.org/en/ev.php-URL_ID=13178&URL_DO=DO_PRINTPAGE&URL_SECTION=201.html"
        }}><strong parentName="a">{`Declaration on the Responsibility of the Present Generations Towards Future Generations`}</strong></a>{` (1997)`}</li>
    </ul>
    <p>{`For an exploration of the history of thinking about human extinction, see:`}</p>
    <ul>
      <li parentName="ul">{`Thomas Moynihan, `}<a parentName="li" {...{
          "href": "https://mitpress.mit.edu/books/x-risk"
        }}><strong parentName="a">{`X-Risk: How Humanity Discovered Its Own Extinction`}</strong></a>{` (2020)`}</li>
    </ul>
    <p>{`For select historical quotations about existential risk, see `}<a parentName="p" {...{
        "href": "https://theprecipice.com/quotations"
      }}><strong parentName="a">{`theprecipice.com/quotations`}</strong></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      